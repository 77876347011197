
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "24ba267b-cb43-4b5f-a450-11cd9ed986f9"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/usr/src/app/src/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
